import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const Maps = ({ dataSource }) => {

    const [Latitude, setLatitude] = useState(null);
    const [Longitude, setLongitude] = useState(null);
    const [lastDateMap, setLastDateMap] = useState(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDgCJRdIE3hpeL8oR9IP5LQQrzn6Olb-Ow"
    });

    useEffect(() => {
        if (dataSource) {
            const response = dataSource.filter((item) => item.SensorCode === "G2008");
            if (response.length > 0) {
                const res = response[0];
                // if (res.Status === 1) {
                // setLastDateMap(res.RefDate.slice(0, 19).replace("T", " "))
                //     setLatitude(res.Value1);
                //     setLongitude(res.Value2);
                // } else {

                // }
                const fetchData = async (retryCount = 0) => {
                    try {
                        const response = await axios.get('https://api.sinergiteknologi.co.id/VenusIOT/api/iot/getEmail');
                        console.log("email", response);
                        const res = response.data
                        const filter = res.coordinates.split(', ');
                        const filterDate = `${res.date} ${res.time}`
                        setLastDateMap(filterDate);
                        setLatitude(filter[0]);
                        setLongitude(filter[1]);

                    } catch (error) {
                        console.log(`Attempt ${retryCount + 1} failed:`, error);
                        if (retryCount < 5) {
                            setTimeout(() => fetchData(retryCount + 1), 1000);
                        } else {
                            console.log('Failed to fetch data after multiple attempts.');
                        }
                    }
                };
                fetchData();
            }
        }
    }, [dataSource]);


    // useEffect(() => {
    //     
    // }, []);

    if (!Latitude || !Longitude) {
        return (
            <div className="flex justify-center items-center h-full w-full text-black font-bold">
                Loading...
            </div>
        );
    }

    const center = {
        lat: parseFloat(Latitude),
        lng: parseFloat(Longitude),
    };

    return (
        <div className="w-full h-96 lg:h-full flex flex-col gap-4 bg-white p-4 rounded-md">

            <div className="flex flex-col-reverse md:flex-row gap-4 justify-between items-center">
                {/* LATLONG */}
                <div className="flex flex-row gap-4 w-full">
                    <div className="flex flex-col gap-2 md:gap-4 w-full items-center justify-center">
                        <h2 className="w-full text-left text-lg md:text-xl">LATITUDE</h2>
                        {/* <div className="flex flex-col gap-1 text-center bg-white w-full py-3 px-2 md:p-4 rounded-lg"> */}
                        {/* <h3 className="text-sm">Last value Latitude</h3> */}
                        <h1 className="text-md md:text-2xl text-blue-500">
                            {center.lat ? (
                                center.lat
                            ) : (
                                0
                            )}
                        </h1>
                        {/* </div> */}
                    </div>
                    <div className="flex flex-col gap-2 md:gap-4 w-full items-center justify-center">
                        <h2 className="w-full text-left text-lg md:text-xl">LONGITUDE</h2>
                        {/* <div className="flex flex-col gap-1 text-center bg-white w-full py-3 px-2 md:p-4 rounded-lg"> */}
                        {/* <h3 className="text-sm">Last value Longitude</h3> */}
                        <h1 className="text-md md:text-2xl text-blue-500">
                            {center.lng ? (
                                center.lng
                            ) : (
                                0
                            )}
                        </h1>
                        {/* </div> */}
                    </div>
                </div>

                <div className="w-full flex justify-end">
                    <p className="text-md md:text-xl">{lastDateMap}</p>
                </div>

            </div>
            {/* <div className="bg-white w-full h-96 lg:h-full flex flex-col gap-4 p-4"> */}
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    center={center}
                    zoom={9}
                >
                    <Marker position={center} />
                </GoogleMap>
            ) : <></>
            }


            {/* </div> */}

        </div>
    );
};

export default Maps;
