import { Tooltip } from 'antd'

const StatusActive = ({ setData }) => {

    const Status = parseFloat(setData.map((item => item.Status)));
    const Date = setData.map((item => item.RefDate)).join(', ').replace("T", " ").replace(".000Z", "");

    return Status ? (
        <Tooltip title="Connect" color="#83CA16">
            <div className="absolute top-2 right-2 p-4 bg-lime-500 rounded-full bg-opacity-20">
                <div className="absolute top-2 right-2 p-2 bg-lime-500 rounded-full"></div>
            </div>
        </Tooltip>
    ) : (
        <div className="relative w-full flex justify-between items-center bg-red-400 bg-opacity-30 py-1 px-2 rounded-lg">
            <p className="text-[11px] xl:text-sm text-red-700 font-semibold">last Update {Date ? Date : "0000-00-00 00:00:00"}</p>
            <Tooltip title="Disconnect" color="#C61E1E">
                <div className="absolute top-[6px] right-[6px] p-2 bg-red-700 rounded-full"></div>
            </Tooltip>
        </div>
    )
}

export default StatusActive