import { Button, DatePicker, Form, message, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
import { getHistoryCal, getLastSPAL, getSPALActivity } from '../../../API/Santan/getData';

const { RangePicker } = DatePicker;
const { Option } = Select;

const FilterSPAL = ({ onData }) => {
    const [loadings, setLoadings] = useState(false);
    const [form] = Form.useForm();

    const [dataSPAL, setDataSPAL] = useState([]);
    const [activity, setActivity] = useState([]);
    const [selectedSPAL, setSelectedSPAL] = useState(null);
    const [selectedActivity1, setSelectedActivity1] = useState(null);
    const [selectedActivity2, setSelectedActivity2] = useState(null);

    useEffect(() => {
        const fetchSPAL = async () => {
            try {
                const res = await getLastSPAL();
                if (res && res.length > 0) {
                    setDataSPAL(res);
                    if (res.length > 0) {
                        const defaultSPAL = res[0];
                        setSelectedSPAL(defaultSPAL.SONumber);
                        form.setFieldsValue({
                            SPAL: defaultSPAL.SONumber,
                        });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchSPAL();
    }, [form]);

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                const res = await getSPALActivity(selectedSPAL);
                setActivity(res);
                if (res && res.length > 0) {  // Ensure res is not null/undefined and has elements
                    const firstActivity = res[0];
                    const lastActivity = res[res.length - 1];
                    setSelectedActivity1(firstActivity.ActivityDate);
                    setSelectedActivity2(lastActivity.ActivityDate);
                    form.setFieldsValue({
                        activity1: firstActivity.ActivityDate,
                        activity2: lastActivity.ActivityDate,
                    });
                    fetchDataReading(firstActivity.ActivityDate.replace("T", " ").replace(".000Z", ""), lastActivity.ActivityDate.replace("T", " ").replace(".000Z", ""))
                }
            } catch (error) {
                console.error("Error fetching SPAL data:", error);
            }
        }

        fetchActivity();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, selectedSPAL]);

    const onSubmit = async (values) => {
        setLoadings(true);
        const { RangeDate, activity1, activity2 } = values;

        const startDateRange = dayjs(activity1.replace("T", " ").replace(".000Z", ""));
        const endDateRange = dayjs(activity2.replace("T", " ").replace(".000Z", ""));

        // console.log(startDateRange);
        // console.log(endDateRange);

        if (endDateRange && startDateRange && endDateRange.isBefore(startDateRange)) {
            message.error('Activity 2 cannot be before Activity 1');
            setLoadings(false);
            return;
        }

        try {
            await fetchDataReading(startDateRange.format('YYYY-MM-DD HH:mm:ss'), endDateRange.format('YYYY-MM-DD HH:mm:ss'));
        } catch (error) {
            console.log(error);
        } finally {
            setLoadings(false);
        }
    };

    const fetchDataReading = async (start, end) => {
        try {
            const response = await getHistoryCal(start, end);
            onData(response);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadings(false);
        }
    };

    return (
        <div>
            <Form
                onFinish={onSubmit}
                form={form}
                initialValues={{
                    SPAL: selectedSPAL,
                    activity1: selectedActivity1,
                    activity2: selectedActivity2,
                }}
            >
                <div className="w-full flex flex-col border rounded-xl items-end p-2 md:p-4 gap-2 md:gap-4">
                    <div className="flex flex-col gap-4">
                        <Form.Item
                            name="SPAL"
                            noStyle
                        >
                            <Select
                                onChange={(value) => setSelectedSPAL(value)}
                                style={{ minWidth: '100px', width: 'auto' }}
                                placeholder="Select SPAL"
                            >
                                {dataSPAL.map((item, index) => (
                                    <Option key={index} value={item.SONumber}>
                                        {item.SONumber} - {item.IsFinish ? "FINISH" : "UNFINISHED"}
                                    </Option>
                                ))}
                            </Select>

                        </Form.Item>

                        <Form.Item
                            name="activity1"
                            noStyle
                        >
                            <Select
                                style={{ minWidth: '100px', width: 'auto' }}
                                placeholder="Select Activity 1"
                            >
                                {activity.map((item) => (
                                    <Option key={item.DetailNo} value={item.ActivityDate}>
                                        {item.ActivityName} - {item.ActivityDate.replace("T", " ").replace(".000Z", "")}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="activity2"
                            noStyle
                        >
                            <Select
                                style={{ minWidth: '100px', width: 'auto' }}
                                placeholder="Select Activity 2"
                            >
                                {activity.map((item) => (
                                    <Option key={item.DetailNo} value={item.ActivityDate}>
                                        {item.ActivityName} - {item.ActivityDate.replace("T", " ").replace(".000Z", "")}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <Button htmlType="submit" icon={<SearchOutlined />} loading={loadings} className="w-full lg:w-10" style={{ width: '100%' }} />
                </div>
            </Form>
        </div>
    );
}

export default FilterSPAL;
