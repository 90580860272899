import { color } from 'd3-color';
import { interpolateRgb } from 'd3-interpolate';
import React, { useEffect, useState } from 'react';
import LiquidFillGauge from 'react-liquid-gauge';

const Filling = ({ dataSource = [] }) => {

    const [valueData, setValueData] = useState(0);
    // const [data, setData] = useState([]);
    const [SumData, setSumData] = useState(0);

    const startColor = "#FF0013"; // crimson
    const endColor = "#005BFF"; // cornflowerblue

    // const maxValue = 100;
    const mobileRadius = 100;
    const desktopRadius = 100;

    const radius = window.innerWidth < 768 ? mobileRadius : desktopRadius;
    const interpolate = interpolateRgb(startColor, endColor);
    const fillColor = interpolate(SumData / 100);
    const gradientStops = [
        {
            key: "0%",
            stopColor: color(fillColor)
                .darker(0.5)
                .toString(),
            stopOpacity: 1,
            offset: "0%"
        },
        {
            key: "50%",
            stopColor: fillColor,
            stopOpacity: 0.75,
            offset: "50%"
        },
        {
            key: "100%",
            stopColor: color(fillColor)
                .brighter(0.5)
                .toString(),
            stopOpacity: 0.5,
            offset: "100%"
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (dataSource.length > 0) {
                    const response = dataSource[0].BBM_IN;
                    if (response) {
                        const convertValue = parseFloat(response);
                        const SumValue = convertValue / 10000 * 100;
                        setSumData(SumValue)
                        setValueData(convertValue);
                        // setData(response);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();

    }, [dataSource]);

    // console.log(SumData);

    return (
        <div className="text-center">
            <p className="text-2xl my-4">FILLING</p>
            <LiquidFillGauge
                style={{ margin: '0 auto' }}
                width={radius * 2}
                height={radius * 2}
                value={SumData}
                percent="L"
                textSize={0.7}
                textOffsetX={0}
                textOffsetY={0}
                textRenderer={(props) => {
                    const value = Math.round(valueData);
                    const radius = Math.min(props.height / 2, props.width / 2);
                    const textPixels = (props.textSize * radius / 2);
                    const valueStyle = {
                        fontSize: textPixels
                    };
                    const percentStyle = {
                        fontSize: textPixels * 0.6
                    };

                    return (
                        <tspan>
                            <tspan className="value" style={valueStyle}>{value}</tspan>
                            <tspan style={percentStyle}>{props.percent}</tspan>
                        </tspan>
                    );
                }}
                riseAnimation
                waveAnimation
                waveFrequency={2}
                waveAmplitude={1}
                gradient
                gradientStops={gradientStops}
                circleStyle={{
                    fill: fillColor
                }}
                waveStyle={{
                    fill: fillColor
                }}
                textStyle={{
                    fill: color('#444').toString(),
                    fontFamily: 'Arial'
                }}
                waveTextStyle={{
                    fill: color('#fff').toString(),
                    fontFamily: 'Arial'
                }}
            />
        </div>
    )
}

export default Filling;
