import React, { useEffect, useState } from 'react'
import Chart from 'react-google-charts'
import StatusActive from '../Status';

const Speedometer = ({ dataSource, sensorCode }) => {
    const [valueData, setValueData] = useState(0);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (dataSource) {
                    const response = dataSource.filter((item) => item.SensorCode === sensorCode);
                    if (response.length > 0) {
                        const convertValue = parseFloat(response[0].Value1);
                        setValueData(convertValue);
                        setData(response);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, [dataSource, sensorCode]);

    const gaugeData = [
        ['Label', 'Value'],
        ['rpm', valueData],
    ];

    return (
        <div className="relative bg-white flex flex-col justify-center items-center w-full p-4 rounded-lg gap-2">
            <StatusActive setData={data} />
            <Chart
                width={window.innerWidth > 768 ? 200 : 190}
                height={window.innerWidth > 768 ? 200 : 190}
                chartType="Gauge"
                data={gaugeData}
                options={{
                    redFrom: 1200,
                    redTo: 1500,
                    yellowFrom: 750,
                    yellowTo: 1200,
                    minorTicks: 5,
                    max: 1500,
                }}
            />
        </div>
    );
}

export default Speedometer;