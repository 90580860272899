import React from 'react'
import ShipStabilization from './ShipStabilization'

const Inclino = ({ dataSource }) => {
    return (
        <div className="flex flex-col w-full rounded gap-4">
            <h2 className="text-2xl">INCLINO</h2>
            <div className="flex flex-row justify-evenly items-center gap-6">
                <ShipStabilization dataSource={dataSource} />
            </div>
        </div>
    )
}

export default Inclino