import React, { useEffect, useState } from 'react'
import Chart from 'react-google-charts'
import StatusActive from '../../../../components/Santan/Status';

const Speedometer = ({ dataSource }) => {
    const [valueData, setValueData] = useState(0);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (dataSource) {
                    const response = dataSource.filter((item) => item.SensorCode === 'RPM001');
                    if (response.length > 0) {
                        const convertValue = parseFloat(response[0].Value1);
                        setValueData(0);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, [dataSource]);

    const gaugeData = [
        ['Label', 'Value'],
        ['rpm', valueData],
    ];

    return (
        <div className="relative flex flex-col gap-4 justify-center items-center w-full">
            <h2 className="w-full text-xl text-left">SPEED</h2>
            <div className="relative bg-white flex flex-col justify-center items-center w-full p-4 rounded-lg gap-2">
                <div className="absolute bg-white bg-opacity-50 w-full h-full flex justify-center items-center top-0 z-10 rounded-xl">
                    <p className="-rotate-12 text-3xl xl:text-4xl font-bold text-red-600 border border-y-4 border-x-0 border-red-600">COMING SOON</p>
                </div>
                <StatusActive setData={data} />
                <Chart
                    width={200}
                    height={200}
                    chartType="Gauge"
                    data={gaugeData}
                    options={{
                        redFrom: 1200,
                        redTo: 1500,
                        yellowFrom: 750,
                        yellowTo: 1200,
                        minorTicks: 5,
                        max: 1500,
                    }}
                />
            </div>
        </div>
    );
}

export default Speedometer;