import React, { useCallback, useEffect, useState } from 'react'
import Filling from './Filling'
import Consumption from './Consumption'
import FilterRD from '../components/FilterRD'
import FilterRDSPAL from '../components/FilterRDSPAL'

const HistoryByTrip = ({ dataSource }) => {

    const [data, setData] = useState([]);

    return (
        <div className="flex flex-col items-center justify-around w-full rounded gap-4 bg-white p-4">
            <div className="flex flex-col gap-4 items-center justify-center">
                <h2 className="text-2xl">HISTORY BY TRIP</h2>
                <FilterRDSPAL onData={(values) => setData(values)} />
            </div>

            <div className="flex flex-col md:flex-row justify-evenly items-center gap-6 w-full">
                <Filling dataSource={data} />
                <Consumption dataSource={data} />
            </div>
        </div>
    )
}

export default HistoryByTrip