import React, { useState } from 'react'
import Filling from './Filling'
import Consumption from './Consumption'
import FilterRDSPAL from '../components/FilterRDSPAL'
import FilterSPAL from '../components/FilterSPAL'

const SPAL = ({ dataSource }) => {
    const [data, setData] = useState([]);
    return (
        <div className="flex flex-col items-center justify-center w-full rounded gap-4 bg-white p-4">
            <h2 className="text-2xl">HISTORY BY ACTIVITY</h2>

            <FilterSPAL onData={(values) => setData(values)} />

            <div className="flex flex-col md:flex-row justify-evenly items-center gap-6 w-full">
                <Filling dataSource={data} />
                <Consumption dataSource={data} />
            </div>
        </div>
    )
}

export default SPAL