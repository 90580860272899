import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Login from './Pages/Login';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Sensor from './Pages/Sensor';
import History from './Pages/History';
import LoginAdmin from './Admin/LoginAdmin';
import DashboardAdmin from './Admin/Dashboard';
import MySidebar from './Admin/components/Sidebar';
import AccountAdmin from './Admin/Account';
import FormAccountAdmin from './Admin/Account/form';
import EditFormAccountAdmin from './Admin/Account/edit';
import axios from 'axios';
import Cookies from "js-cookie";
import Efficiency from './Pages/Efficiency';
import Anomaly from './Pages/Anomaly';
import Analysis from './Pages/Analysis';
import KPI from './Pages/KPI';
import Analityc from './Pages/Analityc';

const Layout = ({ children, toggleSidebar, isSidebarOpen }) => (
  <div className="flex flex-col h-screen">
    <Navbar toggleSidebar={toggleSidebar} />
    <div className="flex flex-1">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <main className="flex-1 overflow-y-auto bg-gray-200">
        {children}
      </main>
    </div>
  </div>
);

const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const PrivateRoute = ({ element }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    // const [loading, setLoading] = useState(true);
    const location = useLocation();

    const cekSession = async () => {
      try {
        const token = Cookies.get('accessToken');
        if (!token) {
          setIsAuthenticated(false);
          return;
        }

        const res = await axios.get('https://iot.sinergiteknologi.co.id/api/user/check-session', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.data) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    useEffect(() => {
      // setLoading(true);
      cekSession();
    }, [location]);

    if (isAuthenticated === null) return null;

    return isAuthenticated ? element : <Navigate to="/" />;
  };


  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route path="/dashboard/*" element={
        <PrivateRoute
          element={
            <Layout toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen}>
              <Routes>
                <Route path="" element={<Sensor />} />
                <Route path="history" element={<History />} />
                <Route path="efficiency" element={<Efficiency />} />
                <Route path="anomaly" element={<Anomaly />} />
                <Route path="analysis" element={<Analysis />} />
                <Route path="kpi" element={<KPI />} />
                <Route path="analytic" element={<Analityc />} />
              </Routes>
            </Layout>
          }
        />
      } />

      <Route
        path="/admin/*"
        element={
          <Routes>
            <Route path="" element={<LoginAdmin />} />
            <Route path="/*" element={
              <PrivateRoute
                element={
                  <MySidebar>
                    <Routes>
                      <Route path="dashboard" element={<DashboardAdmin />} />
                      <Route path="account" element={<AccountAdmin />} />
                      <Route path="account/form" element={<FormAccountAdmin />} />
                      <Route path="account/edit/:id" element={<EditFormAccountAdmin />} />
                    </Routes>
                  </MySidebar>
                }
              />
            } />
          </Routes>
        }
      />
    </Routes>
  );
};

export default App;
