import React, { useEffect, useState } from 'react';
import { Button, Form, DatePicker, Select, Space, Alert, message } from 'antd';
import dayjs from 'dayjs';
import { getMonitoreObject } from '../API/GET_DATA';

const { RangePicker } = DatePicker;

const FilterDate = ({ onFilterData }) => {

    const [form] = Form.useForm();
    // const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [AccountId, setAccountId] = useState([]);
    const [ObjectCode, setObjectCode] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getMonitoreObject("SINERGI");
                setAccountId(res.Account)
                setObjectCode(res.Object)
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, []);


    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const payload = {
                ...values
            }
            onFilterData(payload);
        } catch (error) {

        }
        setLoading(false);
    };

    const onReset = async () => {
        setIsLoading(true);
        form.resetFields();
        window.location.reload();
        setIsLoading(false);
    };

    // const messageAlert = `From ${startRanges} - ${endRanges} / ${TimesRanges} Minute, ${accountName} Ship, ${sensorName}`;

    return (
        <div className="bg-white p-4 rounded-md">
            <Space
                direction="vertical"
                style={{
                    width: '100%',
                }}
            >
                {/* {showAlert === true ?
                    <Alert message={messageAlert} type="info" showIcon />
                    :
                   ""
                } */}
                <Form
                    layout="inline"
                    onFinish={handleSubmit}
                    form={form}
                >
                    <div className="flex w-full flex-col justify-center gap-4">
                        <p className="text-left text-lg font-semibold px-1">Filter Date</p>
                        <div className="w-full flex flex-col md:flex-row gap-4 md:gap-2 md:items-center justify-center">

                            <Form.Item
                                name="RangeDate"
                            >
                                <RangePicker
                                    showTime={{
                                        format: 'HH:mm:ss',
                                    }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>

                            <Form.Item
                                name="Time"
                            >
                                <Select
                                    defaultValue="15 minute"
                                    popupMatchSelectWidth={false}
                                    options={[
                                        {
                                            value: '5',
                                            label: '5 minute',
                                        },
                                        {
                                            value: '10',
                                            label: '10 minute',
                                        },
                                        {
                                            value: '15',
                                            label: '15 minute',
                                        },
                                        {
                                            value: '30',
                                            label: '30 minute',
                                        },
                                        {
                                            value: '45',
                                            label: '45 minute',
                                        },
                                        {
                                            value: '60',
                                            label: '60 minute',
                                        },
                                    ]}
                                />
                            </Form.Item>

                            <Form.Item
                                name="ObjectCode"
                            >
                                <Select
                                    defaultValue="All"
                                    popupMatchSelectWidth={false}
                                    options={[
                                        {
                                            value: '',
                                            label: 'All',
                                        },
                                        ...ObjectCode.map((item) => ({
                                            value: item.ObjectCode,
                                            label: item.ObjectName,
                                        }))
                                    ]}
                                />
                            </Form.Item>

                            <Form.Item
                                name="SensorCode"
                            >
                                <Select
                                    defaultValue="All"
                                    popupMatchSelectWidth={false}
                                    options={[
                                        {
                                            value: '',
                                            label: 'All',
                                        },
                                        ...ObjectCode.map((item) => ({
                                            value: item.SensorId,
                                            label: item.SensorName,
                                        }))
                                    ]}
                                />
                            </Form.Item>

                        </div>

                        <div className="flex w-full justify-end">
                            <Form.Item>
                                <Space>
                                    <Button htmlType="button" onClick={onReset} loading={isLoading} style={{ backgroundColor: "#FFFFFF" }}>
                                        Reset
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        Submit
                                    </Button>
                                </Space>
                            </Form.Item>
                        </div>

                    </div>
                </Form>
            </Space>
        </div>
    )
}

export default FilterDate;
