import axios from "axios";

const baseURL = process.env.REACT_APP_BASEURL;

export const getCurrent = async (AccountId, ObjectCode) => {
    const response = await axios.get(`${baseURL}/dashboard/get/current?AccountId=${AccountId}&ObjectCode=${ObjectCode}`);
    return response.data;
}

export const getHisReading = async (AccountId, ObjectCode, SensorCode, StartDate, EndDate, Time) => {
    const response = await axios.get(`${baseURL}/dashboard/get/history?AccountId=${AccountId}&ObjectCode=${ObjectCode}&SensorCode=${SensorCode}&StartDate=${StartDate}&EndDate=${EndDate}&Time=${Time}`)
    return response.data;
}

export const getMonitoreObject = async (AccountId) => {
    const response = await axios.get(`${baseURL}/dashboard/get/monitored?AccountId=${AccountId}`)
    return response.data;
}

export const getEfficiecy = async (AccountId, ObjectCode, SensorCode, StartDate, EndDate, Time) => {
    const response = await axios.get(`${baseURL}/dashboard/get/efficiencymetrics?AccountId=${AccountId}&ObjectCode=${ObjectCode}&SensorCode=${SensorCode}&StartDate=${StartDate}&EndDate=${EndDate}&Time=${Time}`)
    return response.data;
}

export const getTitleLogo = async (AccountId) => {
    const response = await axios.get(`${baseURL}/dashboard/get/title_logo?AccountId=${AccountId}`);
    return response.data;
}