import axios from "axios"

const baseUrl = process.env.REACT_APP_BASEURL;

export const getDataReading = async (AccountId, ObjectCode, SensorCode) => {
    const response = await axios.get(`${baseUrl}/get/all/sensorReading?AccountId=${AccountId}&ObjectCode=${ObjectCode}&SensorCode=${SensorCode}`);
    return response.data;
}

export const getDataReadings = async (AccountId, ObjectCode) => {
    const response = await axios.get(`${baseUrl}/get/all/sensorReading?AccountId=${AccountId}&ObjectCode=${ObjectCode}`);
    return response.data;
}

export const getDataAll = async () => {
    const response = await axios.get(`${baseUrl}/get/all/sensorReadingERP14`);
    return response.data;
}

export const getDataHistoryReading = async (AccountId, ObjectCode, StartDate, EndDate, Time) => {
    const response = await axios.get(`${baseUrl}/get/gridtable/sensorReading?AccountId=${AccountId}&ObjectCode=${ObjectCode}&StartDate=${StartDate}&EndDate=${EndDate}&Time=${Time}`)
    return response.data.map((row, index) => ({ ...row, key: index + 1 }));
}


export const getDataSensorSetup = async () => {
    const response = await axios.get(`${baseUrl}/get/all/sensorSetup`);
    return response.data
}

export const getHistoryCal = async (StartDate, EndDate) => {
    const response = await axios.get(`${baseUrl}/get/historycal?StartDate=${StartDate}&EndDate=${EndDate}`);
    return response.data;
}

export const getLastSPAL = async () => {
    const response = await axios.get(`${baseUrl}/get/lastspal`);
    return response.data;
}

export const getSPALActivity = async (valueSPAL) => {
    const response = await axios.get(`${baseUrl}/get/activity?spal=${valueSPAL}`);
    return response.data;
}

export const getMonitoredObject = async () => {
    const response = await axios.get(`${baseUrl}/get/MonitoredObject`);
    return response.data;
}