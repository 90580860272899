import React, { useCallback, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { getEfficiecy } from '../../API/GET_DATA';
import FilterDate from '../../components/FilterDate';
import dayjs from 'dayjs';
import { Spin } from 'antd';

const Efficiency = () => {
  const [dataSource, setDataSource] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      const RangeDate = filterData ? filterData.RangeDate : '';
      const StartDate = RangeDate ? dayjs(RangeDate[0]).format('YYYY-MM-DD HH:mm:ss') : '2024-10-08 00:00:00';
      const EndDate = RangeDate ? dayjs(RangeDate[1]).format('YYYY-MM-DD HH:mm:ss') : '2024-10-08 23:30:00';
      const ObjectCode = filterData && filterData.ObjectCode ? filterData.ObjectCode : 'STS1EFKGZ0RKQ999';
      const SensorCode = filterData && filterData.SensorCode ? filterData.SensorCode : '';
      const Time = filterData && filterData.Time ? filterData.Time : '15';

      const response = await getEfficiecy('SINERGI', `${ObjectCode}`, `${SensorCode}`, `${StartDate}`, `${EndDate}`, `${Time}`);

      const formattedData = response.map(item => ({
        ...item,
        RefDate: item.RefDate ? item.RefDate.replace('T', ' ').slice(0, 19) : '0000/00/00'
      }));
      setDataSource(formattedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [filterData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const groupedData = dataSource && Array.isArray(dataSource)
    ? dataSource.reduce((acc, item) => {
      if (!acc[item.Group]) {
        acc[item.Group] = {};
      }
      if (!acc[item.Group][item.Flag]) {
        acc[item.Group][item.Flag] = [];
      }
      acc[item.Group][item.Flag].push(item);
      return acc;
    }, {})
    : {};

  // Function to calculate max and min for each group and flag
  const getMaxMinValues = (data) => {
    const MinValues = data.map(item => item.MinLimit);
    const MaxValues = data.map(item => item.MaxLimit);
    return {
      min: Math.min(...MinValues),
      max: Math.max(...MaxValues),
    };
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spin size="large" /> {/* Loading spinner */}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      <FilterDate onFilterData={(values) => setFilterData(values)} />
      <div className="flex flex-col gap-4">
        {Object.keys(groupedData).map((group) => (
          <div key={group} className="bg-white p-4 rounded-md">
            <h3 className="text-2xl mb-4 font-bold">{group}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {Object.keys(groupedData[group]).map((flag) => {
                const { max, min } = getMaxMinValues(groupedData[group][flag]);

                return (
                  <div key={flag}>
                    <h4 className="text-center mb-2 text-xl">{flag}</h4>
                    <ResponsiveContainer width="100%" height={250}>
                      <LineChart
                        data={groupedData[group][flag]}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="2" />
                        <XAxis dataKey="RefDate" />
                        <YAxis />
                        <Tooltip />
                        {/* ReferenceLine for Min and Max */}
                        <ReferenceLine y={max} label={`Max: ${max}`} stroke="red" />
                        <ReferenceLine y={min} label={`Min: ${min}`} stroke="green" />
                        <Line type="monotone" dataKey="SensorValue" name="Value" stroke="#8884d8" activeDot={{ r: 4 }} />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Efficiency;
