import React, { useEffect, useState } from 'react'
import WaterMeter from '../../../../components/Santan/Charts/WaterMeter';
import StatusActive from '../../../../components/Santan/Status';

const WaterMeterChart = ({ dataSource }) => {

    const [valueData, setValueData] = useState(0);
    const [maxValue, setMaxValue] = useState(125);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (dataSource) {
                    const response = dataSource.filter((item) => item.SensorCode === 'LVL001');
                    const convertValue = parseFloat(response.map((item) => item.Value1));
                    setValueData(convertValue);
                    setData(response);
                    if (convertValue > maxValue) {
                        setMaxValue(Math.ceil(convertValue / 10) * 10);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();

    }, [dataSource, maxValue]);

    return (
        <div className="relative bg-white flex flex-col justify-center items-center w-full p-4 rounded-lg gap-2">
            <StatusActive setData={data} />
            <div className="relative flex">

                <div className="absolute bottom-0 left-20 mb-2">
                    <h2 className="font-semibold text-3xl lg:text-3xl 2xl:text-4xl">{valueData ? valueData : 0}cm</h2>
                </div>

                <WaterMeter
                    theme="light"
                    value={valueData}
                    max={maxValue}
                    // format="°C"
                    // size="normal"
                    width={window.innerWidth > 768 ? 10 : 8}
                    height={window.innerWidth > 768 ? 200 : 190}
                    steps="5"
                />

            </div>
        </div>
    )
}

export default WaterMeterChart