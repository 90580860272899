import React, { useEffect, useState } from 'react';
import StatusActive from '../../../../components/Santan/Status';

const ShipStabilization = ({ dataSource }) => {
    const [valueData1, setValueData1] = useState(0);
    const [valueData2, setValueData2] = useState(0);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (dataSource) {
                    const response = dataSource.filter((item) => item.SensorCode === 'INC001');
                    const convertValue1 = Number(parseFloat(response.map((item) => item.Value2)).toFixed(1));
                    const convertValue2 = Number(parseFloat(response.map((item) => item.Value1)).toFixed(1));
                    setValueData1(convertValue1);
                    setValueData2(convertValue2);
                    setData(response);
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();

    }, [dataSource]);

    const shipRotationStyle1 = {
        transform: `rotate(${valueData1}deg)`,
        transition: 'transform 0.75s ease'
    };

    const shipRotationStyle2 = {
        transform: `rotate(${valueData2}deg)`,
        transition: 'transform 0.75s ease'
    };

    return (
        <div className="flex flex-col md:flex-row justify-evenly items-center gap-4 w-full">
            <div className="relative bg-white flex flex-col justify-center items-center w-full p-4 rounded-lg gap-2">
                <StatusActive setData={data} />
                <div className="relative flex items-center flex-col-reverse justify-center w-[200px] h-[200px] border-[10px] border-gray-400 rounded-full shadow-xl">
                    <h2 className="absolute z-10 top-1 font-semibold text-[30px]">{valueData1}&deg;</h2>
                    <div className="relative w-full h-full flex items-center justify-center" style={shipRotationStyle1}>
                        <div className="w-full py-[6px] bg-gray-400 absolute"></div>
                        <img src="/assets/images/ship.png" alt="ship" className="relative mb-10 w-[56px] z-10" />
                        <div className="w-full h-1/2 bg-blue-500 absolute rounded-b-full bottom-0"></div>
                    </div>
                </div>
            </div>
            <div className="relative bg-white flex flex-col justify-center items-center w-full p-4 rounded-lg gap-2">
                <StatusActive setData={data} />
                <div className="relative flex items-center flex-col-reverse justify-center w-[200px] h-[200px] border-[10px] border-gray-400 rounded-full shadow-xl">
                    <h2 className="absolute z-10 top-1 font-semibold text-[30px]">{valueData2}&deg;</h2>
                    <div className="relative w-full h-full flex items-center justify-center" style={shipRotationStyle2}>
                        <div className="w-full py-[6px] bg-gray-400 absolute"></div>
                        <img src="/assets/images/ship-x.png" alt="ship" className="relative mb-[50px] w-[110px] z-10" />
                        <div className="w-full h-1/2 bg-blue-500 absolute rounded-b-full bottom-0"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShipStabilization;
