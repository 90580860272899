import React, { useState } from 'react'
import Filling from './Filling'
import Consumption from './Consumption'
import FilterRD from '../components/FilterRD'


const HistoryRangeDate = ({ dataSource }) => {

    const [data, setData] = useState([]);


    return (
        <div className="flex flex-col items-center justify-center w-full rounded gap-4 bg-white p-4">
            <h2 className="text-2xl">HISTORY</h2>

            <FilterRD onData={(values) => setData(values)} />

            <div className="flex flex-col md:flex-row justify-evenly items-center gap-6 w-full">
                <Filling dataSource={data.length === 0 ? dataSource : data} />
                <Consumption dataSource={data.length === 0 ? dataSource : data} />
            </div>
        </div>
    )
}

export default HistoryRangeDate