import React, { useEffect, useState } from 'react'
import Engine1 from './Engine1';
import Engine2 from './Engine2';
import FuelLevel from './FuelLevel';
import AvgData from './AvgData';
import Inclino from './Inclino';
import DataStatus from './DataStatus';
import Speedometer from './AvgData/Speedometer';
import Maps from './AvgData/Maps';
import AvgSpeedometer from './AvgData/AvgSpeedometer';
import { getDataReadings, getMonitoredObject } from '../../../API/Santan/getData';

const SantanSensor = () => {

    const [data, setData] = useState([]);
    const [selectedValue, setSelectedValue] = useState('SGM');

    useEffect(() => {
        const fetchDataReading = async () => {
            try {
                const response = await getDataReadings(selectedValue, "G2008")
                setData(response);
            } catch (error) {
                console.log(error);
            }
        }

        fetchDataReading();

    }, [selectedValue]);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const filterData = data.filter((item) => item.SensorCode === "RPM001");
    let lastDateFormatted = ""; // Inisialisasi tanggal terformat
    if (filterData.length > 0) {
        lastDateFormatted = filterData.map((item => item.RefDate)).join(', ').replace("T", " ").replace(".000Z", "");
    }

    // const [cabang, setCabang] = useState([]);
    // const [ship, setShip] = useState([]);

    // const fetchData = async () => {
    //     try {
    //         const response = await getMonitoredObject();
    //         setCabang(response.Cabang);
    //         setShip(response.Ship);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // useEffect(() => {
    //     fetchData();
    // }, []);

    return (
        <div className="w-full p-4">
            <div className="flex flex-col gap-4 justify-between items-start">
                <div className="flex flex-col md:flex-row gap-2 w-full">

                    <div className="w-full">

                        <label className="block mb-2 text-xl font-medium">Branch</label>
                        <select
                            // key={index}
                            // value={selectedValue}
                            // onChange={handleChange}
                            id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <option value="PT.SINERGI TEKNOLOGI SOLUSINDO">PT.SINERGI TEKNOLOGI SOLUSINDO</option>
                        </select>
                    </div>

                    <div className="w-full">
                        <label className="block mb-2 text-xl font-medium">Ship</label>
                        <select
                            // key={index}
                            // value={selectedValue}
                            // onChange={handleChange}
                            id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <option value="SINERGI">SINERGI</option>
                        </select>

                    </div>
                </div>

                <div className="flex flex-col gap-2 w-full">
                    <p className="text-2xl">
                        {lastDateFormatted ? (
                            <h3> {lastDateFormatted}</h3>
                        ) : (
                            <h3>0000-00-00 00:00:00</h3>
                        )}
                    </p>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
                <div className="md:col-span-1">
                    <Speedometer dataSource={data} />
                    <AvgSpeedometer dataSource={data} />
                </div>
                <div className="md:col-span-3">
                    <Maps dataSource={data} />
                </div>
                <div className="md:col-span-2">
                    <Engine1 dataSource={data} />
                </div>
                <div className="md:col-span-2">
                    <Engine2 dataSource={data} />
                </div>
                <div className="md:col-span-2">
                    <FuelLevel dataSource={data} />
                </div>
                <div className="md:col-span-2">
                    <Inclino dataSource={data} />
                </div>
            </div>

        </div>
    )
}

export default SantanSensor