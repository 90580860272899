import { DashboardOutlined, DownOutlined, UpOutlined, LogoutOutlined, LineChartOutlined, SearchOutlined, FundProjectionScreenOutlined, FundViewOutlined, DesktopOutlined, SnippetsOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const [openSubMenu, setOpenSubMenu] = useState(null);

    const handleItemClick = () => {
        if (isOpen) {
            toggleSidebar();
        }
    };

    const handleSubMenuClick = (menuName) => {
        setOpenSubMenu(openSubMenu === menuName ? null : menuName);
    };

    const location = useLocation();

    const isDemoRoute = location.pathname.startsWith('/demo');

    const menu = [
        {
            nama: "Dashboard",
            urlLink: `${isDemoRoute ? "/demo" : "/dashboard"}`,
            icon: (<DesktopOutlined />)
        },
        {
            nama: "History",
            urlLink: `${isDemoRoute ? "/demo/history" : "/dashboard/history"}`,
            icon: (<LineChartOutlined />)
        },
        {
            nama: "Efficiency",
            urlLink: `${isDemoRoute ? "/demo/efficiency" : "/dashboard/efficiency"}`,
            icon: (<FundProjectionScreenOutlined />)
        },
        {
            nama: "Analytic",
            urlLink: `${isDemoRoute ? "/demo/analytic" : "/dashboard/analytic"}`,
            icon: (<SnippetsOutlined />),
        },
        // {
        //     nama: "Anomaly",
        //     urlLink: `${isDemoRoute ? "/demo/anomaly" : "/dashboard/anomaly"}`,
        //     icon: (<SearchOutlined />)
        // },
        // {
        //     nama: "Analysis",
        //     urlLink: `${isDemoRoute ? "/demo/analysis" : "/dashboard/analysis"}`,
        //     icon: (<FundViewOutlined />)
        // },
        // {
        //     nama: "KPI`S",
        //     urlLink: `${isDemoRoute ? "/demo/kpi" : "/dashboard/kpi"}`,
        //     icon: (<DashboardOutlined />)
        // },
    ];

    return (
        <div
            className={`bg-[#212D32] text-white w-32 absolute inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'
                } transition-transform duration-200 ease-in-out sm:relative sm:translate-x-0 h-full z-50`}
        >
            {menu.map((item, index) => (
                <div key={index}>
                    <Link
                        to={item.urlLink}
                        onClick={() => {
                            if (item.urlLink) {
                                handleItemClick();
                            }
                            if (item.subMenu) {
                                handleSubMenuClick(item.nama);
                            }
                        }}
                        className={`block hover:bg-[#2A3B41] py-4 px-2 ${location.pathname === item.urlLink ? 'bg-[#2A3B41]' : ''}`}
                    >
                        <div className="flex items-center justify-center gap-4">
                            <div className="flex flex-col items-center">
                                {item.icon}
                                <p className="text-center">{item.nama}</p>
                            </div>
                            {item.subMenu && (
                                openSubMenu === item.nama ? <UpOutlined /> : <DownOutlined />
                            )}
                        </div>
                    </Link>
                </div>
            ))}

            <div className="absolute bottom-0 left-0 w-full flex justify-center md:hidden">
                <Link to={`/`} className="w-full text-2xl py-4 hover:bg-white hover:bg-opacity-20 flex flex-col items-center justify-center">
                    <LogoutOutlined />
                    <p className="text-lg">Logout</p>
                </Link>
                {/* <Tooltip title="Logout" className="w-full">
                </Tooltip> */}
            </div>
        </div>

    );
};

export default Sidebar;
