import React from 'react'
import WaterLevel from './WaterLevel'
import WaterMeterChart from './WaterMeterChart'

const FuelLevel = ({ dataSource }) => {
    return (
        <div className="flex flex-col w-full rounded gap-4">
            <h2 className="text-2xl">FUEL LEVEL</h2>
            <div className="flex flex-col md:flex-row justify-evenly items-center gap-6">
                <WaterLevel dataSource={dataSource} />
                <WaterMeterChart dataSource={dataSource} />
            </div>
        </div>
    )
}

export default FuelLevel