import React from 'react'
import Temperatur from '../../../../components/Santan/Sensor/Temperatur'
import Speedometer from '../../../../components/Santan/Sensor/Speedometer'

const Engine2 = ({ dataSource }) => {
    return (
        <div className="flex flex-col w-full rounded gap-4">
            <h2 className="text-2xl">ENGINE 2 STARBOARD</h2>
            <div className="flex flex-col md:flex-row justify-evenly items-center gap-6">
                <Temperatur dataSource={dataSource} sensorCode="TMP002" />
                <Speedometer dataSource={dataSource} sensorCode="RPM002" />
            </div>
        </div>
    )
}

export default Engine2