import React, { useState } from 'react'
import { Button, DatePicker, Form } from 'antd'
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons'
import { getHistoryCal } from '../../../API/Santan/getData';

const { RangePicker } = DatePicker;

const StartRange = () => {
    // Ambil tanggal hari ini
    const startOfDay = dayjs().startOf('day'); // Mulai hari ini jam 00:00:00
    const formattedDateTime = startOfDay.format('YYYY-MM-DD HH:mm:ss.SSS');
    return formattedDateTime;
};

// EndRange: Waktu saat ini
const EndRange = () => {
    const currentDate = dayjs();
    const formattedDateTime = currentDate.format('YYYY-MM-DD HH:mm:ss');
    return formattedDateTime;
};

const FilterRD = ({ onData }) => {

    const [loadings, setLoadings] = useState(false);
    const [form] = Form.useForm();

    const onSubmit = async (values) => {
        setLoadings(true);

        const { RangeDate } = values;

        const startDateRange = RangeDate ? dayjs(RangeDate[0]).format('YYYY-MM-DD HH:mm:ss') : StartRange();
        const endDateRange = RangeDate ? dayjs(RangeDate[1]).format('YYYY-MM-DD HH:mm:ss') : EndRange();

        try {
            const response = await getHistoryCal(startDateRange, endDateRange);
            onData(response);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadings(false);
        }
    }

    return (
        <div>
            <Form
                // layout="inline"
                onFinish={onSubmit}
                form={form}
                initialValues={{
                    'RangeDate': [dayjs(StartRange()), dayjs(EndRange())]
                }}
            >
                <div className="w-full flex border rounded-xl p-4 gap-4">
                    <Form.Item
                        name="RangeDate"
                        noStyle
                    >
                        <RangePicker
                            showTime={{
                                format: 'HH:mm:ss',
                            }}
                            format="YYYY-MM-DD HH:mm:ss"
                        />
                    </Form.Item>
                    <Button htmlType="submit" icon={<SearchOutlined />} loading={loadings} style={{ width: '40px' }} />
                </div>
            </Form >
        </div>
    )
}

export default FilterRD
