import React, { useState } from 'react'
import Filling from './Filling'
import Consumption from './Consumption'
import FilterRDSPALNOW from '../components/FilterRDSPALNOW'


const RealTime = ({ dataSource }) => {

    const [data, setData] = useState([]);

    return (
        <div className="flex flex-col items-center justify-center w-full rounded gap-4 bg-white p-4">
            <h2 className="text-2xl">REALTIME</h2>

            <FilterRDSPALNOW onData={(values) => setData(values)} />

            <div className="flex flex-col md:flex-row justify-evenly items-center gap-6 w-full">
                <Filling dataSource={data} />
                <Consumption dataSource={data} />
            </div>
        </div >
    )
}

export default RealTime