import React, { useEffect, useState } from 'react';
import Thermometer from '../Charts/Thermometer';
import StatusActive from '../Status';

const Temperatur = ({ dataSource, sensorCode }) => {

    const [valueData, setValueData] = useState(0);
    const [maxValue, setMaxValue] = useState(600);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (dataSource) {
                    const response = dataSource.filter((item) => item.SensorCode === sensorCode);
                    const convertValue = parseFloat(response.map((item) => item.Value1));
                    setValueData(convertValue);
                    setData(response);
                    if (convertValue > maxValue) {
                        setMaxValue(Math.ceil(convertValue / 10) * 10);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();

    }, [dataSource, maxValue, sensorCode]);

    // console.log(data);
    
    
    return (
        <div className="relative bg-white flex flex-col justify-center items-center w-full p-4 rounded-lg gap-2">
            <StatusActive setData={data} />
            <div className="relative flex">

                <div className="absolute left-20 bottom-10">
                    <h2 className="font-semibold text-2xl lg:text-xl 2xl:text-[28px]">{valueData ? valueData : 0}&deg;C</h2>
                </div>

                <Thermometer
                    theme="light"
                    value={valueData}
                    max={maxValue}
                    format="°C"
                    size="normal"
                    width={window.innerWidth > 768 ? 10 : 8}
                    height={window.innerWidth > 768 ? 200 : 190}
                    steps="4"
                />

            </div>
        </div>
    )
}

export default Temperatur