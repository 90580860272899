import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Select, Space } from 'antd';
import RealTime from './RealTime';
import dayjs from 'dayjs';
import HistoryRangeDate from './HistoryRangeDate';
import HistoryByTrip from './HistoryByTrip';
import RPMKnot from './RPMKnot';
import SPAL from './SPAL';
import { getHistoryCal, getMonitoredObject } from '../../API/Santan/getData';

const StartRange = () => {
    // Ambil tanggal hari ini
    const startOfDay = dayjs().startOf('day'); // Mulai hari ini jam 00:00:00
    const formattedDateTime = startOfDay.format('YYYY-MM-DD HH:mm:ss.SSS');
    return formattedDateTime;
};

// EndRange: Waktu saat ini
const EndRange = () => {
    const currentDate = dayjs();
    const formattedDateTime = currentDate.format('YYYY-MM-DD HH:mm:ss');
    return formattedDateTime;
};

const Analityc = () => {
    // const [cabang, setCabang] = useState([]);
    // const [ship, setShip] = useState([]);

    const [data, setData] = useState([]);

    const fetchDataReading = async (start, end) => {
        try {
            const response = await getHistoryCal(start, end);
            setData(response)
            // setData(response);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchDataReading(StartRange(), EndRange());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const fetchData = async () => {
    //     try {
    //         const response = await getMonitoredObject();
    //         setCabang(response.Cabang);
    //         setShip(response.Ship);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // useEffect(() => {
    //     fetchData();
    // }, []);

    return (
        <div className="w-full p-4">

            <div className="flex flex-col md:flex-row gap-2 w-full mb-4">

                <div className="w-full">

                    <label className="block mb-2 text-xl font-medium">Branch</label>
                    <select
                        // key={index}
                        // value={selectedValue}
                        // onChange={handleChange}
                        id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        <option value="PT.SINERGI TEKNOLOGI SOLUSINDO">PT.SINERGI TEKNOLOGI SOLUSINDO</option>
                    </select>
                </div>

                <div className="w-full">
                    <label className="block mb-2 text-xl font-medium">Ship</label>
                    <select
                        // key={index}
                        // value={selectedValue}
                        // onChange={handleChange}
                        id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        <option value="SINERGI">SINERGI</option>
                    </select>

                </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                <RealTime />
                <HistoryRangeDate dataSource={data} />
                <HistoryByTrip />
                {/* <RPMKnot dataSource={data} /> */}
                <SPAL />
            </div>

        </div>
    )
}
export default Analityc;