import { Button, DatePicker, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
import { getHistoryCal, getLastSPAL } from '../../../API/Santan/getData';

const { Option } = Select;
const { RangePicker } = DatePicker;

const FilterRDSPAL = ({ onData }) => {
    const [loadings, setLoadings] = useState(false);
    const [form] = Form.useForm();

    const [dataSPAL, setDataSPAL] = useState([]);
    const [selectedSPAL, setSelectedSPAL] = useState(null);

    const fetchLastSPAL = async () => {
        try {
            const response = await getLastSPAL();

            if (response) {
                setDataSPAL(response);

                if (response.length > 0) {
                    const defaultSPAL = response[0];
                    const StartDate = defaultSPAL.StartDate.replace("T", " ").replace(".000Z", "");
                    const EndDate = defaultSPAL.EndDate.replace("T", " ").replace(".000Z", "");
                    setSelectedSPAL(defaultSPAL.SONumber);
                    form.setFieldsValue({
                        SPAL: defaultSPAL.SONumber,
                        RangeDate: [
                            dayjs(StartDate),
                            dayjs(EndDate),
                        ],
                    });
                    fetchDataReading(StartDate, EndDate);
                }
            } else {
                console.error("Response does not contain an array for SPAL");
            }
        } catch (error) {
            console.error("Error fetching SPAL data:", error);
        }
    };

    useEffect(() => {
        fetchLastSPAL();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSPALChange = (value) => {
        const selected = dataSPAL.find(item => item.SONumber === value);
        if (selected) {
            const StartDate = selected.StartDate.replace("T", " ").replace(".000Z", "")
            const EndDate = selected.EndDate.replace("T", " ").replace(".000Z", "")
            form.setFieldsValue({
                RangeDate: [
                    dayjs(StartDate),
                    dayjs(EndDate),
                ],
            });
            fetchDataReading(StartDate, EndDate);
        }
    };

    const onSubmit = async (values) => {
        setLoadings(true);
        const { RangeDate } = values;

        const startDateRange = RangeDate ? dayjs(RangeDate[0]).format('YYYY-MM-DD HH:mm:ss') : null;
        const endDateRange = RangeDate ? dayjs(RangeDate[1]).format('YYYY-MM-DD HH:mm:ss') : null;

        fetchDataReading(startDateRange, endDateRange);
    };

    const fetchDataReading = async (start, end) => {
        try {
            const response = await getHistoryCal(start, end);
            onData(response);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadings(false);
        }
    };

    return (
        <div>
            <Form
                onFinish={onSubmit}
                form={form}
                initialValues={{
                    SPAL: selectedSPAL,
                }}
            >
                <div className="w-full flex border rounded-xl items-center justify-center p-2 md:p-4 gap-2 md:gap-4">
                    <div className="flex flex-col xl:flex-row gap-4">
                        <Form.Item
                            name="SPAL"
                            noStyle
                        >
                            <Select
                                onChange={(value) => setSelectedSPAL(value)}
                                style={{ minWidth: '100px', width: 'auto' }}
                                placeholder="Select SPAL"
                            >
                                {dataSPAL.map((item, index) => (
                                    <Option key={index} value={item.SONumber}>
                                        {item.SONumber} - {item.IsFinish ? "FINISH" : "UNFINISHED"}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="RangeDate"
                            noStyle
                        >
                            <RangePicker
                                showTime={{
                                    format: 'HH:mm:ss',
                                }}
                                format="YYYY-MM-DD HH:mm:ss"
                                disabled={true}
                            />
                        </Form.Item>
                    </div>
                    <Button htmlType="submit" icon={<SearchOutlined />} loading={loadings} style={{ width: '40px' }} />
                </div>
            </Form >
        </div>
    );
}

export default FilterRDSPAL;
