import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { LogoutOutlined } from '@ant-design/icons';
import { Button, message, Tooltip } from 'antd';
import { getAccount } from '../Admin/API/GetData';
import axios from 'axios';
import Cookies from 'js-cookie';

const Navbar = ({ toggleSidebar }) => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const dataSession = JSON.parse(localStorage.getItem('Data'));
            const response = await getAccount();
            const filter = response.filter((item => item.AccountId === dataSession.AccountId));
            // console.log(filter);
            setData(filter[0]);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // console.log(data);


    const handleLogout = async () => {
        try {
            const token = Cookies.get('accessToken');
            const res = await axios.get(`https://iot.sinergiteknologi.co.id/api/user/logout`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            message.success(res.data.ResponseMessage);
            localStorage.clear();
            Cookies.remove('accessToken');
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <nav className="bg-[#1b6ab4] shadow-md text-white">
            <div className="flex items-center justify-between px-4 py-2">
                {data ? (
                    <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        {data.IconName ? (
                            <img
                                src={`${process.env.REACT_APP_BASEURL_IMG}/${data.IconName}`}
                                className="h-16 drop-shadow-[0_0_4px_rgba(255,255,255,1)]"
                                alt="Icon"
                            />
                        ) : (
                            <img src="/assets/images/venus-logo.png" className="h-12 drop-shadow-md" alt="Icon" />
                        )}
                        {/* <img src="/assets/images/venus-logo.png" className="h-12 drop-shadow-md" alt="Icon" /> */}
                        <span className="self-center md:text-2xl font-semibold whitespace-nowrap">{data.AccountName}</span>
                        {/* <span className="self-center md:text-2xl font-semibold whitespace-nowrap">VENUS IOT</span> */}
                    </Link>
                ) : (
                    <span className="self-center md:text-2xl font-semibold whitespace-nowrap">VENUS IOT</span>
                )}
                <button
                    className="sm:hidden block focus:outline-none"
                    onClick={toggleSidebar}
                >
                    <svg className="w-[32px] h-[32px] text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M5 7h14M5 12h14M5 17h14" />
                    </svg>
                </button>
                <div className="hidden md:block">
                    <Tooltip title="Logout">
                        <button onClick={handleLogout} className="text-3xl hover:bg-white hover:bg-opacity-20 rounded-full px-3 py-1.5">
                            <LogoutOutlined />
                        </button>
                    </Tooltip>
                </div>

            </div>
        </nav>
    )
}

export default Navbar